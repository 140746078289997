import './App.css';

import { useState, useEffect } from 'react';

function App() {

  const [values, setValues] = useState({ primary: '', secondary: '' });
  const [selectedDuration, setSelectedDuration] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        // const response = await fetch('http://localhost:3000/api/plex/arejessandjessiwatchingharrypotter?apiKey=d09a4781-772f-48f1-8094-0e83073e34f5');
        const response = await fetch('https://api.olympus.nyc/api/plex/arejessandjessiwatchingharrypotter?apiKey=d09a4781-772f-48f1-8094-0e83073e34f5');
        setValues(await response.json());
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (values.durations && values.durations.length > 0) {
      setSelectedDuration(values.durations[0])
    }
  }, [values]);

  return (
    <div className="app">
      <p className="primary">{values.primary}</p>
      <p className="secondary">{values.secondary}</p>
      {values.tertiary && <p className="tertiary">{values.tertiary}</p>}
      {values.durations && selectedDuration && <div className="dur">
        <span className="durations">In the last</span>
        <div className="select">
          <select
            onChange={e => setSelectedDuration(values.durations.filter(x => x.title === e.target.value)[0])}
            name="duration"
            id="duration"
          >
            {values.durations.map(({ title }) => (<option key={title} value={title}>{title}</option>))}
          </select>
          <span class="focus"></span>
        </div>
        <span className="durations">they've watched {selectedDuration.value} of Harry Potter.</span>
      </div>}
    </div>
  );
}

export default App;
